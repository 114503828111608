import { template as template_af7e14d652af4d2583a6ef5cb34757d1 } from "@ember/template-compiler";
const WelcomeHeader = template_af7e14d652af4d2583a6ef5cb34757d1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
