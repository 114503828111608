import { template as template_d6c4f390d33b4f16878c6da99c3a3ae2 } from "@ember/template-compiler";
const FKLabel = template_d6c4f390d33b4f16878c6da99c3a3ae2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
