import { template as template_2061d574ffce4543a5d76945805ab26c } from "@ember/template-compiler";
const SidebarSectionMessage = template_2061d574ffce4543a5d76945805ab26c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
