import { template as template_6a0c865046954d27bf36980a6da7ca16 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_6a0c865046954d27bf36980a6da7ca16(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
